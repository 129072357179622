<template>
    <div class="popover" :class="trans+' '+phaseClass" >
        <h1 class="msTitle">{{ phaseName }}</h1>
        <div class="msInfo">
        <span><strong>{{date}}</strong> {{phaseDelivery}}</span>
        </div>
        <a class="btn progressBtn" :class="trans" data-toggle="modal" data-target="#buildingModal">Buildings Status</a>
    </div>
</template>

<script>
export default {
     props:{
            phaseName: {
                type: String,
                required: true 
            },
            phaseDelivery: {
                type: String,
                required: true 
            },
            date: {
                type: String,
                required: true 
            },
            phaseClass: {
                type: String,
                required: true 
            },
            trans: {
                type: String,
                required: false 
            },
     }
}
</script>

<style scoped>
.popover {
  max-width: 200px !important;
  height: auto;
  padding: 15px;
}
.popover h1 {
  font-family: Lexend;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  color: #365061;
  margin-bottom: 6px !important;
  text-transform: capitalize;
}
.popover .msInfo {
  position: relative;
  display: flex;
  color: #365061;
  width:100%;
}
.popover .msInfo strong {display: block;}
.popover .msInfo span {margin-right: 25px;}
.popover .btn.progressBtn {
  background: #365061;
  border: 2px solid #365061;
  border-radius: 4.46084px;
  color: #fff;
  font-family: Lexend;
  font-weight: bold;
  font-size: 13.3825px;
  line-height: 17px;
  margin-top: 10px;
  transition: all 0.5s ease;
}
.popover .btn.progressBtn:hover {
  color: #365061;
  background: #fff;
}
.popover:after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: #ffffff transparent transparent transparent;
  bottom: -15px;
  right: calc(50% - 7.5px);
}
.transparent{
    background:rgb(255 255 255 / 70%) !important;
}
.transparent:after{
    opacity:0;
}
a.transparent{
    display:none;
}
</style>