<template>
  <navigation class="logoAnimation"/>

    <router-view v-slot="{ Component }">
          <component :is="Component" />
    </router-view>
</template>
<script>
import navigation from "@/components/navigation.vue";
export default {
  name: "Home",
  components: {
    navigation,
  },
}
</script>

    Notification
<style>
* {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 1.3;
  color: #fff;
}
body {
  background: linear-gradient(180deg, #4a6c82 0%, #293d4a 100%);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
body::before{
  content:"";
  position:absolute;
  left:0;top:0;
  background-color: #4a6c82;
  background: url("assets/images/pattern2.png");
  opacity:0.1;
  mix-blend-mode: overlay;
  width:100vw;
  height:100vh;
}
h1 {
  font-family: 'Cinzel', serif;;
  font-weight: bold;
  font-size: 2.3rem;
  width: 100%;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 11px;
}
h2 {
  font-family: 'Lexend', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #ffffff;
}
a {
  text-decoration: none !important;
  color: inherit;
}
a:hover {
  color: inherit;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
/*Bootstrap Fixes*/
@media (min-width: 1300px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 90vw;
        margin: auto;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Lexend:wght@100;300;400;500;600;700;800&display=swap");

.logoAnimation{
  -webkit-animation: slide-in-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  position:relative;z-index:2;
}
</style>
