<template>
    <div class="container" id="navigation">
        <div class="brandLogo">
            <router-link to="../views/home/"><img src="../assets/images/ver-Logo.png" alt="iCity-Logo" class="slide-in-elliptic-left-bck" id="headerLogo"></router-link>
        </div>
        <div class="notification" data-toggle="modal" data-target="#notification" @click="setCookie">
        <a><img src="../assets/images/icons/bell.svg" alt="Notification" class="slide-in-elliptic-left-bck"></a>
        <span class="ind" v-show="notificationBellActive">1</span>
        </div>
    </div>
    <div class="modal fade" id="notification" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Notification</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <span>iCity is the first real estate company in Egypt to sign a Partnership agreement with the Egyptian government.</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueCookies from 'vue-cookies'
export default {
    name: 'navigation',
    data(){
        return {
            notificationBellActive:true,
        }
    },

    methods:{
        setCookie(){
            console.log("cookie set");
            VueCookies.set("notificationOpen","true","30d");
            this.notificationBellActive=false;
        },
    },
    created(){
         if(VueCookies.isKey("notificationOpen")){
            this.notificationBellActive=false;
        }
    }
}
</script>

<style>
    #navigation{position: relative;z-index: 100;}
    .brandLogo{max-width: 148px;padding-top: 50px; position: absolute;transition: all 0.4s;}
    .brandLogo img{max-width: 100%;}
    .brandLogo:hover{transform: translateY(6px);}
    .brandLogo:hover img{-webkit-filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.2));filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.2));}
    
    
    .notification{
    position: absolute;
    right:7vw;
    top:8vh;
    background-color:white;
    padding:15px;
    border-radius: 50%;
    transition: all 0.4s;
    }
    .notification a{display:block;width:20px;height: 20px;}
    .notification img{margin-top: -5px;}
    
    .notification .ind{
        background-color:red;
        width:20px;
        height:20px;
        border-radius: 50%;
        position: absolute;
        right:0;
        text-align: center;
        font-size:0.9rem;
        font-family: sans-serif;
        font-weight: 700;
    }
    .notification:hover{transform: translateY(6px);box-shadow: 0 10px 20px rgba(0, 0, 0, 0.35);cursor: pointer;}


    #notification .modal-body{
        color:black;
        padding:20px !important;
        font-size:1.7rem;
        font-weight: 600;    
    }


    @media (max-height: 800px){
    .brandLogo {max-width: 110px;}
    }
</style>