import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import masterplan from '../views/masterplan.vue'
import cluster from '../views/cluster.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/masterplan',
    name: 'masterplan',
    component: masterplan
  },
  {
    path: '/cluster/:id',
    name: 'cluster',
    component: cluster,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
