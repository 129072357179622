<template>

  <div class="cluster ">
    <div class="row clusterBody align-items-center min-vh-100" :id="$route.params.id">
      <div class="container d-flex ">
        <div class="introContainer col-5 align-middle">
          <div class="colInnerWrapper">
            <div class="slideCaption">
              <div class="introContent text-focus-in delay-05">
                <h1>{{ chosenCluster.title }}</h1>
                <h4 v-if="chosenCluster.subT" style="width:100%;"> {{ chosenCluster.subT }}</h4>
                <h3>{{ chosenCluster.subtitle }}</h3>
                <p>{{ chosenCluster.desc }}</p>
                <!--- Play Call to Action --->
                <a v-if="chosenCluster.Btn" :href="chosenCluster.BtnURL" class="cta" data-toggle="modal" data-target="#videoModal">
                  <span class="icn"><img src="../assets/images/icons/Play.svg"/></span>
                  <span class="txt">
                  {{chosenCluster.BtnContent}}
                  </span>
                </a>
                <!--- Play Call to Action --->
              </div>
            </div>
            <div class="colFooter text-focus-in delay-1">
              <span class="year">{{ chosenCluster.year }}</span>
              <span class="caption">{{ chosenCluster.yearCaption }}</span>
            </div>
          </div>
        </div>
        <div class="imageContainer col-7 justify-content-center puff-in-left delay-05">
          <div class="colInnerWrapper">
            <img
              ref="imMV"
              :src="chosenCluster.imgURl"
              alt="Mountain View MasterPlan"
              class="slideImage "
              :class="chosenCluster.id"
              :popUp="chosenCluster.id"
              @mouseenter="showPopUp($event)"
              @mouseout="hidePopUp($event)"
            />
            <div class="svgContainer" v-if="CDcluster.shows">
                <svg width="1128" height="632" viewBox="0 0 1128 632" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1128" height="632">
                        <g style="mix-blend-mode:screen">
                        <path d="M158.868 65.3452C64.3013 122.358 14.0049 245.539 0.677549 300.003C-0.586884 314.596 -0.122406 322.579 2.4159 336.505C4.15426 346.934 25.0145 380.829 71.0809 363.447C96.8085 340.503 99.1843 311.591 97.1562 300.003C82.3802 291.312 93.2002 264.37 101.502 247.857C182.335 87.0727 325.75 75.7744 343.133 74.0362C360.517 72.298 376.162 82.7272 411.798 88.8109C447.434 94.8947 471.771 23.6283 434.397 7.98451C397.022 -7.65933 277.076 -5.92113 158.868 65.3452Z" fill="#EC008C"/>
                        <path d="M510.015 137.481C537.829 142.348 552.895 155.152 556.951 160.946L580.419 177.459C580.419 177.459 603.017 206.14 614.317 212.223C623.356 217.09 640.102 217.728 647.345 217.438C663.86 216.569 681.243 193.972 682.982 184.412C684.72 174.852 682.982 152.255 671.682 137.481C660.383 122.706 561.297 53.1778 528.268 48.8323C501.845 45.3558 485.389 74.0362 480.463 88.8109C478.725 103.006 482.202 132.614 510.015 137.481Z" fill="#EC008C"/>
                        <path d="M105.848 362.578V630.262L1128 632V610.272C1128 598.974 1105.4 488.598 1055.86 419.07C1006.32 349.542 957.641 327.814 895.93 302.61C834.218 277.406 729.048 285.228 675.159 271.323C632.048 260.198 570.858 213.382 545.652 191.365C545.652 191.365 475.248 139.219 422.228 120.968C298.805 115.753 274.468 134.004 197.112 178.329C135.226 213.788 110.484 315.936 105.848 362.578Z" fill="#EC008C"/>
                        </g>
                    </mask>
                    <g mask="url(#mask0)">
                        <g ref="EV" popUp="phaseEV" @mouseenter="evHover = true; positionPopup(-70,-30);" @mouseleave="evHover = false;">
                          <path opacity="0.51" d="M516.355 137.087C541.713 141.524 555.449 153.198 559.147 158.481L580.543 173.536C580.543 173.536 601.146 199.685 611.448 205.231C619.69 209.669 634.957 210.25 641.561 209.986C656.618 209.193 672.467 188.591 674.051 179.875C675.636 171.159 674.051 150.557 663.75 137.087C653.448 123.616 563.109 60.2259 532.996 56.264C508.906 53.0945 493.902 79.243 489.411 92.7135C487.826 105.656 490.996 132.649 516.355 137.087Z" fill="#0085FF"/>
                        </g>
                        <g ref="E" popUp="phaseE" @mouseenter="eHover = true; positionPopup(215,0);" @mouseleave="eHover = false;">    
                            <path opacity="0.5" d="M635.555 334L667.557 259.5C677.225 251 697.26 233.9 700.06 233.5C702.86 233.1 805.569 243.333 856.574 248.5L1119.1 278L1159.6 660C1125.6 668.667 1056.79 674.9 1053.59 630.5C1049.59 575 1022.09 508 1005.59 479.5C975.084 409.5 877.575 374.5 851.573 367.5C825.571 360.5 709.561 358.5 685.559 354C666.357 350.4 644.222 339.167 635.555 334Z" fill="#0053F4"/>
                        </g>
                        <g ref="D" popUp="phaseD" @mouseenter="dHover = true; positionPopup(115,60);" @mouseleave="dHover = false;">
                            <path opacity="0.35" d="M493.042 241.5L544.047 186L654.056 259.5L621.053 329.5C554.548 310.5 518.211 271.167 493.042 241.5Z" fill="#4200FF"/>
                            <path opacity="0.35" d="M602.552 427.5L628.054 350C652.931 361.89 731.063 376.5 787.068 373.5C825.206 371.457 871.742 388.333 885.576 397.5C873.242 428.167 844.173 489.3 834.572 478.5C822.571 465 798.569 460 793.568 457C788.568 454 715.061 447.5 685.559 447.5C671.958 447.5 624.554 434.167 602.552 427.5Z" fill="#4200FF"/>
                        </g>
                        <g ref="CV" popUp="phaseCV" @mouseenter="cvHover = true; positionPopup(-25,0);" @mouseleave="cvHover = false;">
                        <path opacity="0.3" d="M158.868 65.3452C64.3013 122.358 14.0049 245.539 0.677549 300.003C-0.586884 314.596 -0.122406 322.579 2.4159 336.505C4.15426 346.934 25.0145 380.829 71.0809 363.447C96.8085 340.503 99.1842 311.591 97.1562 300.003C82.3802 291.312 93.2002 264.37 101.502 247.857C182.335 87.0728 325.75 75.7744 343.133 74.0362C360.517 72.298 376.162 82.7272 411.798 88.811C447.434 94.8947 471.771 23.6283 434.397 7.98451C397.022 -7.65933 277.076 -5.92113 158.868 65.3452Z" fill="#00D1FF"/>
                        </g>
                        
                        <g ref="CL" popUp="phaseCL" @mouseenter="clHover = true; positionPopup(115,60);" @mouseleave="clHover = false;">
                        <path opacity="0.5" d="M829.5 617H652H564C558.187 617.044 557.041 617.519 552 615.5L533.5 603.5C529.687 598.975 529.367 596.288 532.5 591L556.5 552C559.5 548 561.5 544 554.5 539.5C547.5 535 467 478.5 467 478.5L342 391C326.722 367.164 326.233 354.709 342 334.5C351.708 318.955 389.569 321.296 399 337C400.899 340.161 423.333 359.167 434 368.5C485.02 411.118 519.681 433.161 604.5 465.5C624 472 666.5 487.5 771.5 490C836 500.5 829.5 551 829.5 565.5V617Z" fill="#0053F4"/>
                        </g>
                        <g ref="c">
                        <a  @mouseenter="cHover = true;showPopUp($event)" @mouseleave="cHover = false;hidePopUp($event)" popUp="phaseC" >
                          <path opacity="0.5" d="M217.5 277L142 231.5L121 237L45 640.5H211.5V582.5H328.5C365.5 520 383.422 493 387 487C390.578 481 393 469.655 382.5 463.5C353.048 446.235 319.124 417.431 297 397C267.544 369.799 288.953 314.546 314 298L266.5 231.5L217.5 277Z" fill="#FFE600"/>
                        </a>
                        </g>
                        <g ref="b"><a  @mouseenter="bHover = true;showPopUp($event)" @mouseleave="bHover = false;hidePopUp($event)" popUp="phaseB" >
                            <path opacity="0.5" d="M215.519 271L152.513 223C152.513 213.333 155.513 188.6 167.514 167C182.516 140 407.035 94.0002 428.037 93.0002C444.838 92.2002 504.377 142 532.046 167C514.544 201 516.044 203 485.542 236C358.006 143.802 251.351 222.705 215.519 271Z" fill="#FF8A00"/>
                            <path opacity="0.5" d="M219.019 639V589H363.031C371.832 589 379.366 599.333 382.033 604.5V639H219.019Z" fill="#FF8A00"/>
                        </a></g>
                        <g ref="a"><a  @mouseenter="aHover = true;showPopUp($event)" @mouseleave="aHover = false;hidePopUp($event)" popUp="phaseA">
                          
                          <path opacity="0.45" d="M404.5 298.5C419.167 310.833 445.5 337 459 348.5C472.5 360 540.892 404.406 594.5 423.5L621 348.5C591.5 336 582.5 330 558.5 315.5C538 303.115 502.667 274.167 488 258C481.5 245.833 454.1 218.3 396.5 205.5C338.9 192.7 291.167 216.833 274.5 230.5L320.5 295.5C363.5 279.5 394.5 290.167 404.5 298.5Z" fill="#FF0000"/>
                          <path opacity="0.45" d="M394.5 489.5L362.5 538C359.128 543.535 359.622 546.353 364.5 551C395.667 572.667 459.173 616.084 468.5 622.5C478.488 629.371 487.5 625.5 491.5 620C495.5 614.5 515.7 583.5 520.5 575.5C525.3 567.5 513.5 558.833 507 555.5C480.667 537.833 420.7 497.1 409.5 489.5C400.5 483.393 396.333 487.5 394.5 489.5Z" fill="#FF0000"/>
                          
                        </a></g>
                    </g>
                </svg>
            </div>
            <div class="popover" :class="MVcluster.id" :ref="MVcluster.id" v-if="MVcluster.shows">
              <h1 class="msTitle">>MV&nbsp;Park</h1>
              <div class="msInfo">
                <span>Project Status<strong>Under Construction</strong></span>
              </div>
              <a class="btn progressBtn"  data-toggle="modal" data-target="#mvModal">Gallery</a>
            </div>
            <div class="popover" :class="MVIIIcluster.id" :ref="MVIIIcluster.id" v-if="MVIIIcluster.shows">
              <h1 class="msTitle">MVIII</h1>
              <div class="msInfo">
                <span>Project Status<strong>Under Construction</strong></span>
              </div>
              <a class="btn progressBtn" data-toggle="modal" data-target="#mvIIIModal">Gallery</a>
            </div>
            <div class="popover" :class="MVIslandcluster.id" :ref="MVIslandcluster.id" v-if="MVIslandcluster.shows">
              <h1 class="msTitle">MVIII Island</h1>
              <div class="msInfo">
                <span>Project Status<strong>Under Construction</strong></span>
              </div>
              <a class="btn progressBtn" data-toggle="modal" data-target="#mvIslandModal">Gallery</a>
            </div>
            <div class="popover" :class="MPcluster.id" :ref="MPcluster.id" v-if="MPcluster.shows">
              <h1 class="msTitle">Mountain&nbsp;Park</h1>
              <div class="msInfo">
                <span>Project Status<strong>Under Construction</strong></span>
              </div>
              <a class="btn progressBtn" data-toggle="modal" data-target="#mpModal">Gallery</a>
            </div>
            <div class="popover" :class="HWcluster.id" :ref="HWcluster.id" v-if="HWcluster.shows">
              <h1 class="msTitle">Mountain&nbsp;Park</h1>
              <div class="msInfo">
                <span>Project Status<strong>Under Construction</strong></span>
              </div>
              <a class="btn progressBtn" data-toggle="modal" data-target="#hwModal">Gallery</a>
            </div>
            <div class="popover phaseA" :class="CDcluster.id" ref="phaseA" v-if="CDcluster.shows" @mouseenter="legendHighligtIn('a')" @mouseleave="legendHighligtOut('a')">
              <h1 class="msTitle">Phase (A)</h1>
              <div class="msInfo">
                <span><strong>June 2022</strong> Delivery Starting</span> 
              </div>
              <a class="btn progressBtn" data-toggle="modal" data-target="#buildingModal">Buildings Status</a>
              <a class="btn progressBtn" data-toggle="modal" data-target="#projectModal">Project Progress</a>
            </div>
            <div class="popover phaseB" :class="CDcluster.id" ref="phaseB" v-if="CDcluster.shows" @mouseenter="legendHighligtIn('b')" @mouseleave="legendHighligtOut('b')">
              <h1 class="msTitle">Phase (B)</h1>
              <div class="msInfo">
                <span><strong>Sep 2021</strong> Delivery</span>
              </div>
              <a class="btn progressBtn" data-toggle="modal" data-target="#buildingModal">Buildings Status</a>
              <a class="btn progressBtn" data-toggle="modal" data-target="#projectModal">Project Progress</a>
            </div>
            <div class="popover phaseC" :class="CDcluster.id" ref="phaseC" v-if="CDcluster.shows" @mouseenter="legendHighligtIn('c')" @mouseleave="legendHighligtOut('c')">
              <h1 class="msTitle">Phase (C)</h1>
              <div class="msInfo">
                <span><strong>Sep 2021</strong> Delivery</span>
              </div>
              <a class="btn progressBtn" data-toggle="modal" data-target="#buildingModal">Buildings Status</a>
              <a class="btn progressBtn" data-toggle="modal" data-target="#projectModal">Project Progress</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--- Modal Sturctures -->
  <div v-if="CDcluster.shows">
    <gallery clusterName="CDGallery" type="buildingS" modal="buildingModal" modalType="gallery" />
    <gallery clusterName="CDGallery" type="projectProgress" modal="projectModal" modalType="galleryTabs"/>
    <gallery clusterName="CDGallery" type="video" modal="videoModal" modalType="video"/>
  </div>
  <div v-if="MVcluster.shows"><gallery clusterName="mvGallery" type="default" modal="mvModal" modalType="gallery"/></div>
  <div v-if="MVIIIcluster.shows"><gallery clusterName="mvIIIGallery" type="default" modal="mvIIIModal" modalType="gallery"/></div>
  <div v-if="MVIslandcluster.shows"><gallery clusterName="mvIslandGallery" type="default" modal="mvIslandModal" modalType="gallery"/></div>
  <div v-if="MPcluster.shows"><gallery clusterName="mpGallery" type="default" modal="mpModal" modalType="gallery"/></div>
  <div v-if="HWcluster.shows">
    <gallery clusterName="hwGallery" type="video" modal="videoModal" modalType="video"/>
    <gallery clusterName="hwGallery" type="default" modal="hwModal" modalType="gallery"/>
    </div>
  <!--  Modal Sturctur end   -->


  <!--- Small Popups Start --->
  <div v-if="CDcluster.shows">
    <popup v-show="evHover" phaseClass="phaseEV" trans="transparent"
      phaseName="Phase (E) Villa" 
      phaseDelivery="Delivery Starting" 
      date="June 2022" 
      @mouseenter="evHover = true; legendHighligtIn('EV');" @mouseleave="evHover = false;legendHighligtOut('EV');"
    />
    <popup v-show="cvHover" phaseClass="phaseCV" trans="transparent"
      phaseName="Phase (C) Villa" 
      phaseDelivery="Delivery Starting" 
      date="June 2022"
      @mouseenter="cvHover = true; legendHighligtIn('CV');" @mouseleave="cvHover = false;legendHighligtOut('CV');" 
    />
    <popup v-show="eHover" phaseClass="phaseE" trans="transparent"
      phaseName="Phase (E)" 
      phaseDelivery="Delivery Starting" 
      date="June 2022" 
      @mouseenter="eHover = true; legendHighligtIn('E');" @mouseleave="eHover = false;legendHighligtOut('E');"
    />
    <popup v-show="dHover" phaseClass="phaseD" trans="transparent"
      phaseName="Phase (D)" 
      phaseDelivery="Delivery Starting" 
      date="June 2022"
      @mouseenter="dHover = true;legendHighligtIn('D');" @mouseleave="dHover = false;legendHighligtOut('D');" 
    />
    <popup v-show="clHover" phaseClass="phaseCL" trans="transparent"
      phaseName="Phase (Club)" 
      phaseDelivery="Delivery Starting" 
      date="June 2022"
      @mouseenter="clHover = true;legendHighligtIn('CL');" @mouseleave="clHover = false;legendHighligtOut('CL');"
    />
  </div>
  <!--- Small Popups End --->
</template>
<script>
//----------- Cluster Maps ------------//
import mvMap from "../assets/images/cluster/mvPark.png";
import mviiiMap from "../assets/images/cluster/mviii.png";
import mvislandMap from "../assets/images/cluster/mvIsland.png";
import mpMap from "../assets/images/cluster/mountainPark.png";
import cdMap from "../assets/images/cluster/cd.png";
import hwMap from "../assets/images/cluster/heartwork.png";
//----------- Cluster Maps ------------//

import gallery from "../components/gallery.vue"
import popup from "../components/popup.vue"
export default {
  name: "cluster",
  components: { 
    gallery,
    popup,
  },
  props: {
    cluster: {
      type: String,
    },
  },
  data: function () {
    return {
      evHover:false,
      cvHover:false,
      eHover:false,
      dHover:false,
      clHover:false,
      aHover:false,
      bHover:false,
      cHover:false,
      
      chosenCluster : {},
      MVcluster: {
        id: "puMVx",
        title: "MV Park",
        subtitle: "Project Overview",
        desc:
          "We know that plenty of people pay a great deal of attention to privacy when they look for their home, and MV Park ISLAND came into existence to offer the highest level possible of privacy and exclusivity. Privately gated and separate from the rest of the compound, we developed MV PARK ISLAND to be nothing short of its name; an island. That's because the homes of MV PARK ISLAND are a select collection of spacious villas that have all the ample space your family needs to grow and thrive, with panoramic horizon views directly overlooking iCity's magnificent Central Park, all the while being surrounded by shimmering blue lakes.",
        year: "2024",
        yearCaption: "Delivery Date",
        imgURl: mvMap,
        svgURL: "",
        shows: false,
        hasPhases: false,
      },
      MVIIIcluster: {
        id: "puMVIIIx",
        title: "MVIII",
        show: false,
        subtitle: "Project Overview",
        desc:
          "If you missed out on the exclusive opportunity to live in Mountain View I and II, now is your third chance. A compound with only a limited number of boutique villas, Mountain View III takes the Mountain View Concept and perfects it, in a double-gated community with luxury villas of all sizes that suit the tastes of our discerning customers.      This select compound provides a privileged lifestyle for its residents within their niche, exclusive community. The American-style design of our homes with their fenceless front yards fosters a neighborly spirit within a community of like-minded people, combined with the peace and tranquility of a spacious home, resulting in a unique blend of intimacy and privacy hard to find elsewhere.         If you'd like to see how our vision translates into reality, visit Mountain View I, and Mountain View II today.",
        year: "2024",
        yearCaption: "Delivery Date",
        imgURl: mviiiMap,
        svgURL: "",
        hasPhases: false,
      },
      MVIslandcluster: {
        id: "puMVIslandx",
        title: "MVIII Island",
        show: false,
        subtitle: "Project Overview",
        desc:
          "iCity is the first real estate company in Egypt to sign a Partnership agreement with the Egyptian government.",
        year: "2024",
        yearCaption: "Delivery Date",
        imgURl: mvislandMap,
        svgURL: "",
        hasPhases: false,
      },
      MPcluster: {
        id: "puMPx",
        title: "Mountain Park",
        show: false,
        subtitle: "Project Overview",
        desc:
          "Rise in a life that is so heart-captivating that it retrieves everyone from the distractions of the fast-paced life, where you're surrounded by raw nature and horizon views that make you feel you own the world. When you're at the top the air pressure drops, and with it every other pressure, leaving you only with the purest life there is. Although life on mountain is a life everyone should dream of, we know that 'the perfect life' isn't a fixed concept. This is why Mountain Park is plethora of diverse homes built on three zones; Altitude, Hilltop, and Mountain Residence. They All share the pressure-free life of the mountain, but each zone has its own distinctive aspects, so you can have the life that's perfect for you!",
        year: "2024",
        yearCaption: "Delivery Date",
        imgURl: mpMap,
        svgURL: "",
        hasPhases: false,
      },
      CDcluster: {
        id: "puCDx",
        title: "Club District",
        subT: "*TO BE UPDATED MONTHLY",
        show: false,
        subtitle: "Project Overview",
        desc:
          "When you choose your new home at The Club Park, you won't only be choosing a new home... you'll be choosing a new life. You'll get to enjoy a truly unique view and lead a healthy lifestyle making use of the jogging track as well as iCity Club at your door step. You'll enjoy a seamlessly connected Cornish as well as zero road intersections for the perfect stress free life.",
        Btn: true,
        BtnContent:"Monthly Updates",
        BtnURL:"",
        year: "",
        yearCaption: "",
        imgURl: cdMap,
        svgURL: "",
        hasPhases: false,
      },
      HWcluster: {
        id: "puHWx",
        title: "Heart Work",
        show: false,
        subtitle: "Project Overview",
        desc:
          "Heartwork is the very first international Business Hub in Egypt. Designed to afford high levels of luxury and convenience within New Cairo. Heartwork offers office spaces ranging from 55 sqm up to 8000 sqm or own a whole office building starting 2000 sqm. Sign up to learn more about this project!",
        Btn: true,
        BtnContent:"Heartwork",
        BtnURL:"",
        year: "2024",
        yearCaption: "Delivery Date",
        imgURl: hwMap,
        svgURL: "",
        hasPhases: false,
      }
    };
  },
  methods: {
    positionPopup(leftDef,topDef){
      let popUp = event.target.getAttribute("popUp");
      let leftPos = event.target.getBoundingClientRect().left;
      let topPos = event.target.getBoundingClientRect().top;
      document.getElementsByClassName(popUp)[0].style.left = leftPos+leftDef+"px";
      document.getElementsByClassName(popUp)[0].style.top = topPos+topDef+"px";
    },
    showPopUp: function (event) {
      let el = event.target;
      let popUp = el.getAttribute("popUp");
      switch (popUp) {
        case "puMVx":
          this.$refs.puMVx.style.opacity = "1";
          this.$refs.puMVx.style.left = "auto";
          this.$refs.puMVx.style.top = "0";
          break;
        case "puMVIIIx":
          this.$refs.puMVIIIx.style.opacity = "1";
          this.$refs.puMVIIIx.style.left = "auto";
          this.$refs.puMVIIIx.style.top = "0";
          break;
        case "puMVIslandx":
          this.$refs.puMVIslandx.style.opacity = "1";
          this.$refs.puMVIslandx.style.left = "auto";
          this.$refs.puMVIslandx.style.top = "0";
          break;
        case "puHWx":
          this.$refs.puHWx.style.opacity = "1";
          this.$refs.puHWx.style.left = "auto";
          this.$refs.puHWx.style.top = "10%";
          break;
        case "puMPx":
          this.$refs.puMPx.style.opacity = "1";
          this.$refs.puMPx.style.left = "auto";
          this.$refs.puMPx.style.top = "0";
          break;
        case "phaseA":
          this.$refs.phaseA.style.opacity = "1";
          this.$refs.phaseA.style.left = "30%";
          this.$refs.phaseA.style.top = "20%";
          break;
        case "phaseB":
          this.$refs.phaseB.style.opacity = "1";
          this.$refs.phaseB.style.left = "10%";
          this.$refs.phaseB.style.top = "-5%";
          break;
        case "phaseC":
          this.$refs.phaseC.style.opacity = "1";
          this.$refs.phaseC.style.left = "10%";
          this.$refs.phaseC.style.top = "15%";
          break;
      }
    },
    hidePopUp: function (event) {
      let el = event.target;
      let popUp = el.getAttribute("popUp");
      switch (popUp) {
       case "puMVx":
          this.$refs.puMVx.style.opacity = "0";
          this.$refs.puMVx.style.left = "-999px";
          break;
        case "puMVIIIx":
          this.$refs.puMVIIIx.style.opacity = "0";
          this.$refs.puMVIIIx.style.left ="-999px";
          break;
        case "puMVIslandx":
          this.$refs.puMVIslandx.style.opacity = "0";
          this.$refs.puMVIslandx.style.left = "-999px";
          break;
        case "puMPx":
          this.$refs.puMPx.style.opacity = "0";
          this.$refs.puMPx.style.left = "-999px";
          break;
        case "puHWx":
          this.$refs.puHWx.style.opacity = "0";
          this.$refs.puHWx.style.left = "-999px";
          break;
        case "phaseA":
          this.$refs.phaseA.style.opacity = "0";
          this.$refs.phaseA.style.left = "-999px";
          break;
        case "phaseB":
          this.$refs.phaseB.style.opacity = "0";
          this.$refs.phaseB.style.left = "-999px";
          break;
        case "phaseC":
          this.$refs.phaseC.style.opacity = "0";
          this.$refs.phaseC.style.left = "-999px";
          break;
      }
    },
     legendHighligtIn : function (x) {
      let svg = this.$refs[x];
      svg.style.opacity="1";
      },
    legendHighligtOut : function (x) {
      let svg = this.$refs[x];
      svg.style.opacity="";
    },
  },
  mounted() {
      var cluster = this.$route.params.id;
      switch(cluster){
          case 'mv' : 
            this.MVcluster.shows = true;
            this.chosenCluster = this.MVcluster; break
          case 'mviii' :
            this.MVIIIcluster.shows = true;
            this.chosenCluster = this.MVIIIcluster; break 
          case 'mvisland' :
            this.MVIslandcluster.shows = true;
            this.chosenCluster = this.MVIslandcluster; break 
          case 'mp' :
            this.MPcluster.shows = true;
            this.chosenCluster = this.MPcluster; break 
          case'cd' : 
            this.CDcluster.shows = true;
            this.chosenCluster = this.CDcluster; break 
          case'hw' : 
            this.HWcluster.shows = true;
            this.chosenCluster = this.HWcluster; break 
      }
  },
};
</script>
<style scoped>

.clusterBody,
.imageContainer {
  height: calc(100vh - 120px);
  position: relative;
}
.imageContainer .colInnerWrapper {position: relative;justify-content: center;display: flex;}
.introContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}
.clusterBody .container .imageContainer{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.clusterBody .container .imageContainer::before {
  content:"";
  background: url(../assets/images/Masterplan/Masterplan.png);
  background-size: 90%;
  background-position: right center;
  background-repeat: no-repeat;
  width: 90%;
  height: 90%;
  right:0;
  position: absolute;    
  opacity:0.3;
  align-items: center;
}
.slideImage {
  max-width: 70% !important;
  margin: auto;
  display: block;
}
.puMVIslandx.slideImage{max-width: 60% !important;}
.slideImage.puCDx{max-width: 100% !important;}
.slideImage.puHWx{max-width: 55% !important;margin-top:5%;}
.colFooter .year {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.5;
  display: block;
}

/******** Popover UI ********/
.clusterBody .popover:hover {
  opacity: 1 !important;
  left: auto !important;
}
.clusterBody .popover.phaseA:hover{left: 30% !important}
.clusterBody .popover.phaseB:hover,.clusterBody .popover.phaseC:hover{left: 10% !important}

.clusterBody .popover {
  max-width: 200px !important;
  height: auto;
  padding: 15px;
  opacity: 0;
  left: -999px;
}
.clusterBody .popover h1 {
  font-family: Lexend;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  color: #365061;
  margin-bottom: 6px !important;
  text-transform: capitalize;
}
.clusterBody .popover .msInfo {
  position: relative;
  display: flex;
  color: #365061;
  width:100%;
}
.clusterBody .popover .msInfo strong {display: block;}
.clusterBody .popover .msInfo span {margin-right: 25px;}
.clusterBody .popover .btn.progressBtn {
  background: #365061;
  border: 2px solid #365061;
  border-radius: 4.46084px;
  color: #fff;
  font-family: Lexend;
  font-weight: bold;
  font-size: 13.3825px;
  line-height: 17px;
  margin-top: 10px;
  transition: all 0.5s ease;
}
.clusterBody .popover .btn.progressBtn:hover {
  color: #365061;
  background: #fff;
}
.clusterBody .popover:after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: #ffffff transparent transparent transparent;
  bottom: -15px;
  right: calc(50% - 7.5px);
}
/***** Sub title UI *****/
#cd h4 {
    font-family: Lexend;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
}
#cd h1 {
    margin: 0;
}


/******** Club distrect SVG UI *********/
.svgContainer {
    position: absolute;
    bottom: 6px;
    max-width: 100%;
    max-height: 100%;
    top: 1px;
}
.svgContainer svg {
    max-width:100%;
    height: auto;
}
.svgContainer svg g{bottom: 0;}
.svgContainer svg g g {
    opacity: 0;
    cursor: pointer;
}
.svgContainer svg g g:hover {opacity: 1;}
.clusterBody .popover.puCDx {
    max-width: 436px !important;
    width: 326px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}






/*------------- Button UI -------------------*/
.cta{
  font-weight: 700;
  color:#4A6C82;
  margin-top:4%;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 40px 10px 10px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
  background-color: #fff;
}
.cta span.icn{
  border-radius:50%;
  margin-right:20px;
}
.cta span.txt{
  font-size:1.2rem;
  vertical-align: middle;
}
/*------------- Button UI -------------------*/
/*---------------- Button animation --------------*/

.cta:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.cta:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.cta::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
  background-color: #fff;
}
.cta:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

/*---------------- Button animation --------------*/

/************     Responsive      *************/
@media (max-width: 1600px){
  .introContent {transform:scale(0.9);transform-origin: bottom left;}
}
@media (max-height: 800px){
  .introContent {transform:scale(0.8);transform-origin: bottom left;}
  .slideImage.puMVIIIx ,.slideImage.puMPx{max-width: 60% !important;}
}

</style>