<template>
  <div class="carousel-item">
    <img
      class="d-block w-100"
      :src="imageURL"
      alt="First slide"
    />
    <div class="carousel-caption">
      <h5>{{ title }}</h5>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
    props:["title","description","imageURL"],
}
</script>