<template>
  <div class="masterplan">
    <div class="slideCaption text-focus-in delay-15">
      <h1>iCity New Cairo Masterplan</h1>
      <div class="introContent">
        <p>
          iCity is the first real estate company in Egypt to sign a
          Partnership agreement with the Egyptian government.
        </p>
      </div>
    </div>
    <div class="row" id="masterplan">
      <div class="container">
        <div class="col-10">
          <div class="colInnerWrapper" id="x">
            
            <div class="mxImgMapWrapper">
              <div class="LagoonPlaceHolder justify-content-center">
                <img src="../assets/images/Masterplan/LagoonIcon.svg" class="popoverIc">
                <h3>Lagoon Park</h3>
                <p>Project Status <br/><strong>Under Construction</strong></p>
              </div>
              <img
                src="../assets/images/Masterplan/Masterplan.png"
                id="myimage"
                alt="Mountain View MasterPlan"
                class="slideImage"
              />
              <div class="svgContainer" ref="svgCon">
                <svg
                  width="1268"
                  height="839"
                  viewBox="0 0 1268 839"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <router-link to="/cluster/mv" @mouseover="showPopUp($event)" @mouseout="hidePopUp($event)" popup="puMV">
                  <path
                    d="M343.174 36.6436H57.4612C51.3476 36.6436 46.3916 41.5996 46.3916 47.7131V257.972C46.3916 264.086 51.3218 269.042 57.4352 269.091C111.537 269.53 219.311 272.725 243.857 272.812C244.554 272.815 245.181 272.878 245.864 273.018C278.982 279.844 306.542 309.571 327.08 325.255C343.68 337.931 339.53 353.676 335.38 359.963C331.859 365.119 327.382 376.941 337.644 382.977C345.163 387.4 354.493 403.168 361.119 416.327C364.334 422.712 372.707 425.079 378.983 421.656C390.984 415.112 410.184 406.421 435.356 399.199C471.378 388.865 490.839 355.063 496.495 338.411C496.742 337.684 497.049 337.002 497.375 336.307C503.368 323.508 505.151 280.255 505.151 276.587C505.151 272.814 499.995 270.676 496.851 271.305C482.666 276.134 464.029 269.293 456.483 265.269C441.77 256.214 378.766 217.356 371.221 212.828C360.796 207.906 355.789 193.791 354.4 186.546C354.288 185.958 354.243 185.364 354.243 184.765V47.7131C354.243 41.5996 349.287 36.6436 343.174 36.6436Z"
                    fill="#CFA44A"
                    fill-opacity="0.7"
                    stroke="#CFA44A"
                    stroke-width="4.5"
                    stroke-opacity="0.8"
                    ref="MVSVG" 
                  />
                  </router-link>
                  <router-link to="/cluster/mviii"  @mouseover="showPopUp($event)" @mouseout="hidePopUp($event)" popup="puMVIII">
                  <path
                    d="M203.457 291L203.534 291.001L203.611 290.998C205.777 290.918 211.6 290.82 218.274 291.521C225.021 292.23 232.286 293.722 237.551 296.627L237.586 296.646L237.622 296.665C267.134 311.919 286.325 324.001 305.633 339.348C306.101 339.72 306.928 340.716 307.878 342.391C308.785 343.989 309.668 345.963 310.346 348.034C311.027 350.114 311.469 352.195 311.562 354.018C311.657 355.887 311.372 357.194 310.909 357.989C310.364 358.922 309.805 359.966 309.206 361.086C305.661 367.71 300.693 376.992 288.886 381.715C285.562 383.044 281.335 385.596 276.796 388.707C272.21 391.85 267.135 395.684 262.073 399.7C251.948 407.733 241.772 416.577 235.485 422.278C209.533 445.807 195.449 464.542 186.212 480.373C181.604 488.271 178.226 495.406 175.21 501.951C174.856 502.719 174.508 503.476 174.164 504.225C171.558 509.899 169.186 515.065 166.387 520.026C164.6 523.195 161.538 528.166 158.205 532.714C156.539 534.988 154.836 537.114 153.217 538.841C151.559 540.61 150.151 541.786 149.088 542.353L149.088 542.353L148.855 542.477C141.269 546.525 130.19 552.354 117.424 550.53C103.662 548.564 98.6693 545.226 96.4 542.2C93.506 538.342 90.8286 533.787 89.9516 528.964L89.8291 528.29L89.4262 527.736C88.0773 525.881 86.1334 524.419 84.065 523.238C81.9727 522.044 79.5725 521.038 77.1261 520.172C73.0574 518.732 68.5938 517.591 64.8581 516.637C64.2234 516.475 63.6098 516.318 63.0226 516.166C61.0471 515.223 57.6665 513.229 54.7749 510.258C51.7798 507.18 49.5 503.265 49.5 498.5L49.5001 321C49.5001 319.908 49.4782 318.756 49.4555 317.562C49.3501 312.014 49.2275 305.559 51.2012 300.016C52.3514 296.786 54.1682 294.065 57.0318 292.142C59.91 290.21 64.1363 288.889 70.4474 289C93.2535 289.4 168.626 290.5 203.457 291Z"
                    fill="#FF0000"
                    fill-opacity="0.3"
                    stroke="#FF0000"
                    stroke-width="4.5"
                    stroke-opacity="0.8"
                    ref="MVIIISVG" 
                  />
                  </router-link>
                  <router-link to="/cluster/mvisland" popup="puMVI" @mouseover="showPopUp($event)" @mouseout="hidePopUp($event)">
                  <path
                    d="M280.146 474.624L279.996 474.755L279.868 474.908C278.105 477.019 274.517 479.611 270.217 481.084C265.944 482.548 261.33 482.789 257.305 480.771C252.983 478.604 249.084 474.677 245.927 470.465C242.853 466.363 240.627 462.186 239.511 459.588C239.327 458.906 239.082 458.124 238.842 457.36C238.686 456.862 238.532 456.372 238.4 455.924C238.01 454.602 237.671 453.229 237.508 451.746C237.191 448.85 237.526 445.347 239.864 441.014C240.311 440.185 241.533 438.721 243.641 436.663C245.681 434.672 248.358 432.307 251.485 429.701C257.736 424.492 265.684 418.409 273.7 412.578C281.716 406.748 289.769 401.194 296.224 397.041C299.454 394.963 302.259 393.253 304.446 392.038C305.541 391.429 306.452 390.962 307.167 390.638C307.524 390.476 307.81 390.36 308.032 390.282C308.245 390.206 308.345 390.185 308.357 390.183C308.358 390.183 308.358 390.183 308.358 390.183C311.647 389.682 314.651 389.495 317.408 390.144C320.076 390.771 322.664 392.219 325.077 395.297C327.819 398.794 332.655 405.135 336.733 411.327C338.774 414.429 340.589 417.438 341.853 420.003C342.486 421.286 342.956 422.409 343.25 423.344C343.557 424.317 343.613 424.904 343.589 425.182C343.424 427.143 342.006 429.075 339.769 430.707C337.552 432.324 335.048 433.257 333.689 433.441L333.138 433.515L332.67 433.817C310.972 447.806 299.384 457.887 280.557 474.267L280.531 474.289L280.146 474.624Z"
                    fill="#F9D100"
                    fill-opacity="0.35"
                    stroke="#F9D100"
                    stroke-width="4.5"
                    stroke-opacity="0.8"
                    ref="MVISVG"
                    
                  />
                  </router-link>
                  <router-link to="/cluster/mp" popup="puMP" @mouseover="showPopUp($event)" @mouseout="hidePopUp($event)">
                   <path
                    d="M295.971 708.531V788.989C296.674 799.156 286.505 804.868 280.885 805.081H59.6263C49.1668 805.081 43.87 791.671 42.5291 784.966V523.478C43.3672 523.311 46.8537 523.076 54.0949 523.478C63.1464 523.981 68.6778 525.993 79.2379 532.53C89.798 539.067 87.2837 555.159 91.3066 558.679C94.5249 561.495 100.023 560.858 102.37 560.187C110.415 563.204 110.415 572.275 112.93 570.245C123.389 561.796 141.09 557.338 148.633 556.164C154.164 555.662 163.216 542.587 166.233 537.056C177.296 524.484 189.264 491.195 196.907 480.735C204.551 470.276 214.172 470.008 218.028 471.181C238.142 478.221 251.719 489.787 261.776 505.878C265.296 512.416 270.083 522.473 266.805 533.536C262.782 547.113 246.15 561.696 240.153 580.302C224.464 628.979 256.748 670.314 274.851 684.897C274.851 684.897 295.971 705.011 295.971 708.531Z"
                    fill="white"
                    fill-opacity="0.5"
                    stroke="white"
                    stroke-width="4.5"
                    stroke-opacity="0.8"
                    ref="MPSVG"
                  /></router-link>
                  <router-link to="/cluster/cd" @mouseover="showPopUp($event);legendHighligtIn('CDSVG')"  @mouseout="hidePopUp($event);legendHighligtOut('CDSVG')">
                  <g ref="CDSVG" popup="puCD" >
                    <path
                      d="M354.806 471.181C300.094 504.168 270.995 575.441 263.285 606.953C262.553 615.397 262.822 620.016 264.291 628.073C265.296 634.108 277.365 653.719 304.017 643.662C318.901 630.386 320.276 613.658 319.102 606.953C310.554 601.925 316.814 586.336 321.617 576.781C368.383 483.752 451.355 477.215 461.412 476.209C471.469 475.204 480.521 481.238 501.138 484.758C521.755 488.278 535.835 447.043 514.212 437.992C492.589 428.94 423.195 429.946 354.806 471.181Z"
                      fill="#EC008C"
                      fill-opacity="0.25"
                      stroke="#EC008C"
                      stroke-width="4.5"
                      stroke-opacity="0.8"
                    />
                    <path
                      d="M557.962 512.918C574.053 515.734 582.769 523.143 585.116 526.495L598.693 536.05C598.693 536.05 611.768 552.644 618.305 556.164C623.535 558.98 633.223 559.349 637.414 559.181C646.968 558.678 657.025 545.604 658.031 540.073C659.037 534.541 658.031 521.467 651.494 512.918C644.956 504.37 587.63 464.141 568.522 461.626C553.235 459.615 543.714 476.209 540.864 484.758C539.858 492.971 541.87 510.102 557.962 512.918Z"
                      fill="#EC008C"
                      fill-opacity="0.25"
                      stroke="#EC008C"
                      stroke-width="4.5"
                      stroke-opacity="0.8"
                    />
                    <path
                      d="M324.131 643.159V798.04L915.496 799.046V786.475C915.496 779.937 902.421 716.074 873.758 675.845C845.095 635.616 816.935 623.045 781.232 608.462C745.529 593.879 684.682 598.405 653.505 590.359C628.563 583.922 593.162 556.835 578.579 544.096C578.579 544.096 537.847 513.924 507.173 503.364C435.766 500.347 421.686 510.907 376.932 536.553C341.128 557.069 326.813 616.172 324.131 643.159Z"
                      fill="#EC008C"
                      fill-opacity="0.25"
                      stroke="#EC008C"
                      stroke-width="4.5"
                      stroke-opacity="0.8"
                    />
                  </g>
                  </router-link>

                  <router-link to="/cluster/hw" popup="puHW" @mouseover="showPopUp($event)" @mouseout="hidePopUp($event)">
                  <path ref="HWSVG" popup="puHW" d="M1159 516.5H1234.5C1244.5 516.5 1247.33 526.167 1247.5 531V741.5C1247.5 748.7 1243.17 755.5 1241 758C1230 769.333 1202.5 797.5 1202.5 797.5C1202.5 797.5 1196.5 805 1183 805C1124.17 805 1002.4 804 994 804C985.6 804 982.5 796.333 982 792.5V690.5C982 684.1 985 677.167 986.5 674.5C1036.17 625 1136.7 525 1141.5 521C1146.3 517 1155.17 516.333 1159 516.5Z" fill="#00AEEF" fill-opacity="0.5" stroke="#00AEEF" stroke-width="5"/>
                  </router-link>
                </svg>
                <div class="popover mv" ref="puMV" @mouseenter="legendHighligtIn('MVSVG')" @mouseleave="legendHighligtOut('MVSVG')">
                  <h1 class="msTitle"><img src="../assets/images/icons/bigPalace.svg" class="popoverIc">MV&nbsp;Park</h1>
                  <div class="msInfo"><span><strong>2024</strong> Delivery</span><span><strong>+265</strong> Altitude</span></div>
                  <a href="/cluster/mv" class="btn progressBtn">View Progress</a>
                </div>
                <div class="popover mp" ref="puMP" @mouseenter="legendHighligtIn('MPSVG')" @mouseleave="legendHighligtOut('MPSVG')">
                  <h1 class="msTitle"><img src="../assets/images/icons/mountain.svg" class="popoverIc">Mountain&nbsp;Park</h1>
                  <div class="msInfo"><span><strong>2024</strong> Delivery</span><span><strong>+300</strong> Altitude</span></div>
                  <a href="/cluster/mp" class="btn progressBtn">View Progress</a>
                </div>
                <div class="popover cd" ref="puCD" @mouseenter="legendHighligtIn('CDSVG')" @mouseleave="legendHighligtOut('CDSVG')">
                  <h1 class="msTitle"><img src="../assets/images/icons/golf.svg" class="popoverIc">Club&nbsp;Distrcit</h1>
                  <div class="msInfo"><span><strong>2020</strong> Delivery Started</span><span><strong>Phase1</strong> Livable</span><span><strong>+265</strong> Altitude</span></div>
                  <a href="/cluster/cd" class="btn progressBtn">View Progress</a>
                </div>
                <div class="popover mviii" ref="puMVIII" @mouseenter="legendHighligtIn('MVIIISVG')" @mouseleave="legendHighligtOut('MVIIISVG')">
                  <h1 class="msTitle"><img src="../assets/images/icons/golf.svg" class="popoverIc">MVIII</h1>
                  <div class="msInfo"><span><strong>2023</strong> Delivery</span><span><strong>+265</strong> Altitude</span></div>
                  <a href="/cluster/mviii" class="btn progressBtn">View Progress</a>
                </div>
                <div class="popover mvi" ref="puMVI" @mouseenter="legendHighligtIn('MVISVG')" @mouseleave="legendHighligtOut('MVISVG')">
                  <h1 class="msTitle"><img src="../assets/images/icons/golf.svg" class="popoverIc">MVIII Island</h1>
                  <div class="msInfo"><span><strong>2021</strong> Livable</span><span><strong>+265</strong> Altitude</span></div>
                  <a href="/cluster/mvisland" class="btn progressBtn">View Progress</a>
                </div>
                <div class="popover hw" ref="puHW" @mouseenter="legendHighligtIn('HWSVG')" @mouseleave="legendHighligtOut('HWSVG')">
                  <h1 class="msTitle"><img src="../assets/images/icons/golf.svg" class="popoverIc">Heartwork</h1>
                  <div class="msInfo"><span> Project Status<strong>Under Construction</strong></span></div>
                  <a href="/cluster/hw" class="btn progressBtn">Information</a>
                </div>
              </div>
            </div>
            <ul class="projectsList">
              <li @mouseenter="legendHighligtIn('MVSVG')" @mouseleave="legendHighligtOut('MVSVG')">
                <router-link to="/cluster/mv">
                  <span class="circle bdorange">●</span><span class="proName">MV PARK</span>
                </router-link>
              </li>
              
              <li @mouseenter="legendHighligtIn('MVIIISVG')" @mouseleave="legendHighligtOut('MVIIISVG')">
                <router-link to="/cluster/mviii">
                  <span class="circle red">●</span><span class="proName">MVIII 150 Villa’s</span>
                </router-link>
              </li>
              <li @mouseenter="legendHighligtIn('MVISVG')" @mouseleave="legendHighligtOut('MVISVG')">
                <span class="circle yellow">●</span><span class="proName">MVIII Island</span>
              </li>
              <li @mouseenter="legendHighligtIn('MPSVG')" @mouseleave="legendHighligtOut('MPSVG')">
                <router-link to="/cluster/mp">
                  <span class="circle white">●</span><span class="proName">Mountain Park</span>
                </router-link>
              </li>
              <li @mouseenter="legendHighligtIn('CDSVG')" @mouseleave="legendHighligtOut('CDSVG')">
                <router-link to="/cluster/cd">
                  <span class="circle purple">●</span><span class="proName">CLUB PARK</span>
                </router-link>
              </li>
              <li @mouseenter="legendHighligtIn('HWSVG')" @mouseleave="legendHighligtOut('HWSVG')">
                <router-link to="/cluster/mv">
                <span class="circle blue">●</span><span class="proName">HEARTWORK</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "masterplan",
  components: {
  },
  methods: {
    legendHighligtIn : function (x) {
      let svg = '';
      switch(x){
        case ("MVSVG") : svg = this.$refs.MVSVG; break
        case ("MPSVG") : svg = this.$refs.MPSVG; break
        case ("MVIIISVG") : svg = this.$refs.MVIIISVG; break
        case ("MVISVG") : svg = this.$refs.MVISVG; break
        case ("CDSVG") : svg = this.$refs.CDSVG; break
        case ("HWSVG") : svg = this.$refs.HWSVG; break
      }
      svg.style.opacity = "1"
      let paths =svg.childNodes;
      if(paths.length > 0){
        for(var i=0; i<paths.length; i++) {
          paths[i].style.opacity = "1";
        }
      }
    },
    legendHighligtOut : function (x) {
      let svg = '';
      switch(x){
        case ("MVSVG") : svg = this.$refs.MVSVG; break
        case ("MPSVG") : svg = this.$refs.MPSVG; break
        case ("MVIIISVG") : svg = this.$refs.MVIIISVG; break
        case ("MVISVG") : svg = this.$refs.MVISVG; break
        case ("CDSVG") : svg = this.$refs.CDSVG; break
        case ("HWSVG") : svg = this.$refs.HWSVG; break
      }
      svg.style.opacity = "0"
      let paths =svg.childNodes;
      if(paths.length > 0){
        for(var i=0; i<paths.length; i++) {
          paths[i].style.opacity = "0";
        }
      }
    },
    showPopUp : function(event){
      let el = event.target.parentElement;
      //let parent = this.$refs.svgCon;
      let popUp = el.getAttribute("popup");
      switch(popUp){
        case "puMV" : 
          this.$refs.puMV.style.opacity = "1";
          this.$refs.puMV.style.left = "0";
          this.$refs.puMV.style.top = "0";
          break;
        case "puMP" : 
          this.$refs.puMP.style.opacity = "1";
          this.$refs.puMP.style.left = "0";
          this.$refs.puMP.style.bottom = "30%";
          this.$refs.puMP.style.top = "auto";
          break;
        case "puCD" : 
          this.$refs.puCD.style.opacity = "1";
          this.$refs.puCD.style.left = "40%";
          this.$refs.puCD.style.top = "auto";
          this.$refs.puCD.style.bottom = "15%";
          break;
        case "puMVIII" : 
          this.$refs.puMVIII.style.opacity = "1";
          this.$refs.puMVIII.style.left = "0";
          this.$refs.puMVIII.style.top = "12%";
          break;
        case "puMVI" : 
          this.$refs.puMVI.style.opacity = "1";
          this.$refs.puMVI.style.left = "13%";
          this.$refs.puMVI.style.top = "18%";
          break;
          case "puHW" : 
          this.$refs.puHW.style.opacity = "1";
          this.$refs.puHW.style.left = "80%";
          this.$refs.puHW.style.top = "35%";
          break;
      }
    },
    hidePopUp : function(event){
      let el = event.target.parentElement;
      let popUp = el.getAttribute("popup");
      switch(popUp){
        case "puMV" : 
          this.$refs.puMV.style.opacity = "0";
          this.$refs.puMV.style.left = "-999px";
          break;
        case "puMP" : 
          this.$refs.puMP.style.opacity = "0";
          this.$refs.puMP.style.left = "-999px";
          break;
        case "puCD" : 
          this.$refs.puCD.style.opacity = "0";
          this.$refs.puMP.style.left = "-999px";
          break;
        case "puMVIII" : 
          this.$refs.puMVIII.style.opacity = "0";
          this.$refs.puMVIII.style.left = "-999px";
          break;
        case "puMVI" : 
          this.$refs.puMVI.style.opacity = "0";
          this.$refs.puMVI.style.left = "-999px";
          break;
        case "puHW" : 
        this.$refs.puHW.style.opacity = "0";
        this.$refs.puHW.style.left = "-999px";
        break;
      }
    }
  },
};
</script>

<style>
.masterplan > .container {
  margin: 0 auto;
}
ul.projectsList a {
    display: flex;
    align-items: center;
}
#x {
    width: fit-content;
    margin: auto;
}
.col-10 {
  margin: auto;
}
.mxImgMapWrapper {
  display: flex;
  justify-content: center;
      position: relative;
}
.masterplan .slideCaption {
  position: absolute;
  max-width: 30rem;
  right: 15vw;
  top: 10vh;
}
.masterplan {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top:10vh;
}
.slideImage {
  max-width: 50vw !important;
    width: 100%;
    display: block;
    height: 100%;
}
.mxImgMapWrapper div {position: absolute;max-width: 50vw!important;width: 100%;display: block;height: 100%;}
/* ------------ Lagoon Text Placeholder ----------- */
.LagoonPlaceHolder{
  top:25%;
  left:50%;
  width: auto !important;
  height: auto !important;
}
.LagoonPlaceHolder h3{
  margin-top:5px;
  font-size:1rem;
  font-weight: 600;
  text-transform: uppercase;
}
.LagoonPlaceHolder p{
  font-size:0.9rem;
}
/* ------------ Lagoon Text Placeholder ----------- */
.projectsList {
  justify-content: space-around;
  width: 100%;
  display: flex;
  margin: 10px auto 0;
  align-items: end;
}
.projectsList .circle {
  font-size: 2.5rem;;
  display: inline-block;
  margin-right: 15px;
  max-width: 14px;
  line-height: 14px;
}
.projectsList li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 13px;
}
.msTitle img {margin-right: 7.5px;}
.mxImgMapWrapper div svg {
    max-width: 100%;
    max-height: 100%;
}
.projectsList li span {
  flex-grow: 1;
  font-family: "Lexend", sans-serif;
  font-size: 0.9rem;
  line-height: 16px;
  color: #ffffff;
}

.projectsList span.proName{
  margin-top:5px;
}
.projectsList .delDate {
  text-align: right;
  margin-right: 40px;
}
.orange {
  color: #f7941e !important;
}
.yellow {
  color: #F9D100 !important;
}
.bdorange {
  color: #cfa44a !important;
}
.blue {
  color: #00aeef !important;
}
.purple {
  color: #ec008c !important;
}
.grey {
  color: #ccdac2 !important;
}
.red{
  color: #FF0000 !important;
}
.projectsList li .delDate {
  font-weight: 500 !important;
}
.mxImgMapWrapper div.cd:hover{
  left:40% !important;
}
.mxImgMapWrapper div.mvi:hover{
  left:15% !important;
}
.mxImgMapWrapper div.hw:hover{
  left:80% !important;
}
.mxImgMapWrapper .popover:hover {
    opacity: 1 !important;
    left: auto !important;
}
.mxImgMapWrapper .popover {
    max-width: 230px !important;
    height: auto;
    padding: 15px;
    opacity: 0;
    left: -999px;
}
.mxImgMapWrapper .popover h1 {
    font-family: Lexend;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display:flex;
    color: #365061;
    margin-bottom: 6px;
    text-transform: capitalize;
}
.mxImgMapWrapper .popover .msInfo {
    position: relative;
    display: flex;
    color: #365061;
}
.mxImgMapWrapper .popover .msInfo strong{display: block;}
.mxImgMapWrapper .popover .msInfo span{margin-right: 25px;}
.mxImgMapWrapper .popover .btn.progressBtn{
    background: #365061;
    border: 2px solid #365061;
    border-radius: 4.46084px;
    color: #fff;
    font-family: Lexend;
    font-weight: bold;
    font-size: 13.3825px;
    line-height: 17px;
    margin-top: 10px;
    transition: all 0.5s ease;
}
.mxImgMapWrapper .popover .btn.progressBtn:hover{
    color:#365061;
    background:#fff;
}
.mxImgMapWrapper .popover:after{
    width: 0;
    height: 0;
    content:'';
    position: absolute;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    border-color: #ffffff transparent transparent transparent;
    bottom: -15px;
    right: calc(50% - 7.5px);
}
.mxImgMapWrapper path , .mxImgMapWrapper g path{
  opacity: 0;
}
.mxImgMapWrapper path:hover, .mxImgMapWrapper g:hover path{
  opacity: 1  !important;
}




/************ Smoothing Hovers  ************/
.mxImgMapWrapper path , .mxImgMapWrapper g path{
transition: all 0.5s;
}

/************     Responsive      *************/
@media (max-width: 1600px){
  .projectsList .proName{font-size:0.8rem;}
  .projectsList .circle{font-size:2rem;}
  .LagoonPlaceHolder {top: 22%;}
  .masterplan .slideCaption{right:9vw;}
}
@media (min-width: 1750px){
  .slideImage,.mxImgMapWrapper div{max-width: 60vw !important;}
  .masterplan .slideCaption h1{font-size:3rem;}
  .projectsList .proName{font-size:1.2rem;}
  .projectsList .circle{font-size:2.4rem;}
}
@media (max-width: 1750px){
  .slideImage,.mxImgMapWrapper div{max-width: 55vw !important;}
}
@media (max-height: 800px){
  .slideImage, .mxImgMapWrapper div{max-width:45vw !important;}
  
}
</style>
