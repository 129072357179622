<template>
  <div class="home">
  <gallery clusterName="homeVideo" type="video" modal="videoModal" modalType="video"/>
    <div class="row introSec" id="introSec">
      <div class="container">
        <div class="col-5">
          <div class="colInnerWrapper">
            <div class="slideCaption text-focus-in delay-15">
              <button class="videoThumbnail" data-toggle="modal" data-target="#videoModal">
                <div>
                <span class="icn"><img src="../assets/images/icons/wPlay.svg"/></span>
                <span class="txt"><p>How to</p><strong>Get there</strong></span>
                </div>
              </button>
              <h1 class="puff-in-center">
                Welcome To<br> Mountain view iCity project update tour
              </h1>
              <router-link to="/masterplan" class="cta">
                <span class="icn"><img src="../assets/images/icons/MV.png"/></span>
                <span class="txt">iCity New Cairo</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="colInnerWrapper maxSBL">
      <img
        src="../assets/images/villa.png"
        alt="Mountain View Villa"
        class="slide-in-br delay-2"
      />
    </div>
  </div>
</template>

<script>
import gallery from "../components/gallery.vue"
export default {
  
  name: "Home",
  components: {
    gallery,
  },
  data(){
    return{
      
    }
  },
  methods: {
  },
  mounted(){
  }
};

</script>

<style >
/*.home:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: url(../assets/images/screen1BgTrans.png);
  z-index: -1;
  background-size: contain;
}*/
.home>.container {
    margin-bottom: 3vh;
}

.home {
    min-height: 100vh;
}

div#introSec {bottom: 10vh;position: absolute;width: 100vw;left: 0;}


.maxSBL img {
  max-width: 75vw;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: -6vw;
}

.col-5,.col-4{
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}
.colFooter {
  position: absolute;
  width: 100%;
  bottom: 2vh;
}
.colFooter > p {
  margin: 0 0 0.5rem;
}


/*----- Animation ------*/

/*------------- Button UI -------------------*/
.cta{
  font-weight: 700;
  color:black;
  margin-top:8px;
  padding: 10px 40px 10px 10px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
  background-color: #fff;
}
.cta span.icn{
  border-radius:50%;
  margin-right:20px;
}
.cta span.icn img{
  max-width: 40px;
}
.cta span.txt{
  font-size:1.2rem;
  vertical-align: middle;
}
/*------------- Button UI end -------------------*/

/*---------------- Button animation start --------------*/

.cta:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: #0056b3 !important;
}
.cta::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
  background-color: #fff;
}
.cta:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
/*---------------- Button animation end --------------*/
.videoThumbnail{
  display:block;
  width:240px;
  height:135px;
  border-radius: 8px;
  position: relative;
  background:url("../assets/images/gal1.jpeg");
  background-size:contain;
  margin-bottom:20px;
  color:white;
  text-align: left;
  border:0;
  transition: all 0.4s;
}
.videoThumbnail:hover{
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.videoThumbnail div{
  display:inline-flex;
  position: relative;
  left:5px;
  top:25%;
}
.videoThumbnail .icn{
  margin-right:10px;
}
.videoThumbnail p{margin:0;}
.videoThumbnail::before{
        content:"";
        width:100%;
        height:100%;
        border-radius: 8px;
        position: absolute;
        left: 0;
        top: 0;
        display:block;
        background: rgb(0,0,0);
        background: linear-gradient(45deg, rgba(0,0,0,0.55) 0%, rgba(0,0,0,0) 100%);
    }


/************     Responsive      *************/
@media (min-width: 1750px){
  .introSec h1{font-size:2.7rem;}
}
@media (max-width: 1750px){
  .introSec h1{font-size:2.3rem;}
}
@media (max-height: 800px){
  .videoThumbnail, .cta{
    transform:scale(0.85);
    transform-origin: bottom left;
  }
  
}
</style>
