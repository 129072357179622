<template>
    <div class="modal fade" :id="modal" tabindex="-1" role="dialog" aria-hidden="true" @click="videoHandle">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Gallery</h5>
                    <div class="tabsContainer" v-if="modalType == 'galleryTabs'">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="tab-1" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Facilities</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="tab-2" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Utilities</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="tab-3" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">Contact</a>
                            </li>
                        </ul>
                    </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body" v-if=" modalType == 'gallery'">
                    <div :id="type" class="carousel slide" data-ride="false">
                        <div class="carousel-inner">
                            <carousel-item v-for="(item, index) in items" :class="{ 'active': index === 0 }" v-bind:key="index" :title="item.title" :description="item.description" :imageURL="require('@/assets/images/' + item.imageURL)"/>
                        </div>
                        <a class="carousel-control-prev" :href="'#'+type" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" :href="'#'+type" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div class="modal-body" v-if="modalType == 'video'">
                    <div :id="type" class="carousel slide" data-ride="false">
                        <div class="carousel-inner">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe ref="YTVideo" class="embed-responsive-item" :src="videoURL" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-body" v-if="modalType == 'galleryTabs'">
                <div class="tab-content" id="myTabContent">

                    <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab-1">
                        <div :id="type" class="carousel slide" data-ride="false">
                            <div class="carousel-inner">
                                <carousel-item v-for="(item, index) in items" :class="{ 'active': index === 0 }" v-bind:key="index" :title="item.title" :description="item.description" :imageURL="require('@/assets/images/' + item.imageURL)"/>
                            </div>
                            <a class="carousel-control-prev" :href="'#'+type" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" :href="'#'+type" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
                        <div :id="type" class="carousel slide" data-ride="false">
                            <div class="carousel-inner">
                                <carousel-item v-for="(item, index) in items" :class="{ 'active': index === 0 }" v-bind:key="index" :title="item.title" :description="item.description" :imageURL="require('@/assets/images/' + item.imageURL)"/>
                            </div>
                            <a class="carousel-control-prev" :href="'#'+type" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" :href="'#'+type" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab-3">
                        <div :id="type" class="carousel slide" data-ride="false">
                            <div class="carousel-inner">
                                <carousel-item v-for="(item, index) in items" :class="{ 'active': index === 0 }" v-bind:key="index" :title="item.title" :description="item.description" :imageURL="require('@/assets/images/' + item.imageURL)"/>
                            </div>
                            <a class="carousel-control-prev" :href="'#'+type" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" :href="'#'+type" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
                </div>


            </div>
        </div>
    </div>

</template>
<script>
    import data from "../assets/data.json";
    import carouselItem from "../components/carouselItem";
    export default {
        data:function(){
            return{
                items:'',
                videoURL:data.videos[this.clusterName],
            };
        },
        props:{
            clusterName: {
                type: String,
                required: true 
            },
            type: {
                type: String,
                required: false 
            },
            modal: {
                type: String,
                required: false 
            },
            modalType: {
                type: String,
                required: false 
            },
        },
        mounted(){
            if(this.clusterName !== "homeVideo"){
                this.items = data.imageGallery[this.clusterName][this.type];
            }
        },
        methods:{
            videoHandle(){
                if(this.videoURL.includes("?autoplay=0")){
                    this.videoURL = data.videos[this.clusterName];
                }else{
                this.videoURL = data.videos[this.clusterName]+"?autoplay=0";
                }
            },
        },
        components: {
            carouselItem,
        }
    };
</script>
<style>

    /* Tabs UI */
    .tabsContainer{display:flex;margin:auto;align-items: center;}
    .tabsContainer ul li a{padding:12px 20px;}
    .tabsContainer li{margin:0 0.8rem;}
    .tabsContainer a{color:#4A6C82; font-weight: 600;border:0;border-radius: 0 !important;font-size: 1.2rem;}
    .tabsContainer a.active{ color:white !important; background-color:#4A6C82 !important; }
    .tabsContainer a.active:hover{background-color:#293d4a !important;cursor: pointer;}
    .tabsContainer a:hover:not(.active){background-color:lightgray; cursor: pointer;}
    .tabsContainer a:hover:not(.active){color:#293d4a !important;}
    .tabsContainer .nav-tabs{border:0;}

    .modal-header{align-items: center ;}
    .modal-body{padding:0;}
    .modal-content{border-radius: 0;}
    .modal-title{color:black;font-weight: 700;}
    .close{opacity:1;font-size:2.1rem;padding:0.6rem 1.5rem; margin-left:0 !important;}
    .close:hover{opacity:0.5;}
    .carousel-caption{
        text-align: left; 
        left:7%;
    }
    .carousel-item::before{
        content:"";
        width:100%;
        height:100%;
        position: absolute;
        display:block;
        background: rgb(0,0,0);
        background: linear-gradient(45deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
    }
    .carousel-caption h5{font-size:2.5rem;font-weight: 600;}
    .carousel-caption p{font-size:1.3rem;font-weight: 300;}
    .galleryThumb {
        position: relative;
        max-width: 140px;
        margin-top: 20px;
    }
    .galleryThumb img{max-width: 100%;}

</style>